.App {
  margin: 0 auto;
  min-height: 100vh;
  max-width: 978px;
  position: relative;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

@media (min-width: 600px) {
  .action-sheet {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}