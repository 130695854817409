$gray-200: #e9ecef !default;
$gray-800: #343a40 !default;
$primary: #0076ff !default;
$secondary: #e6050f;
$success2: #ab03ff;
$success: #00bd93;
$warning: #ffa600;
$danger: #dc3545;
$light: $gray-200;
$dark: $gray-800;

body {
  background: #efeff4;
}

.list-item__thumbnail {
  border-radius: 50% !important;
}

.form-label {
  text-transform: uppercase;
  font-size: 9px;
  width: 90px;
  align-items: flex-start !important;

  label {
    padding-top: 8px;
  }
}

.ml-1 {
  margin-left: 4px
}

.mb-2 {
  margin-bottom: 8px;
}

.img-fluid {
  display: block;
  max-width: 100%;
}

.text-muted {
  color: #555;
}

.text-success {
  color: $success;
}

.text-danger {
  color: $danger;
}

.text-error {
  font-size: 0.8em;
  color: #a00000;
}

.bottom-bar.right {
  justify-content: flex-end;
}

.right .badge {
  margin-left: 4px;
}

.badge {
  background-color: $primary;
  color: #fff;
  font-size: 0.8rem;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.6rem;
}

.bg-success {
  background-color: $success;
  color: #fff;
}

.bg-danger {
  background-color: $danger;
  color: #fff;
}

.bg-warning {
  background-color: $warning;
  color: #222;
}

.bg-dark {
  background-color: $dark;
  color: #fff;
}

.bg-light {
  background-color: $light;
  color: #000;
}